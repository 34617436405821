/* ============================ sliders.js START ================================================================= */
let sliderProducts;

if ($(window).width() >= 667) {
   sliderProducts = new Swiper('.slider-products', {
      slidesPerView: 2.1,
      watchOverflow: true,
      spaceBetween: 8,
      speed: 300,
      navigation: {
         nextEl: '.slider-products__button-next',
         prevEl: '.slider-products__button-prev',
      },
      pagination: {
         el: '.slider-products__pagination',
         clickable: true,
      },
      breakpoints: {
         667: {
            slidesPerView: 4.25,
            spaceBetween: 20,
         },
      },
      keyboard: {
         enabled: true,
         onlyInViewport: false,
      },
   });
}

const sliderReviews = new Swiper('.slider-reviews', {
   slidesPerView: 2.1,
   spaceBetween: 8,
   speed: 300,
   breakpoints: {
      667: {
         slidesPerView: 4.25,
         spaceBetween: 20,
      },
   },
   navigation: {
      nextEl: '.slider-reviews__button-next',
      prevEl: '.slider-reviews__button-prev',
   },
   pagination: {
      el: '.slider-reviews__pagination',
      clickable: true,
   },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
});
const sliderHero = new Swiper('.slider-hero', {
   effect: 'fade',
   slidesPerView: 1,
   speed: 300,
   navigation: {
      nextEl: '.slider-hero__button-next',
      prevEl: '.slider-hero__button-prev',
   },
   keyboard: {
      enabled: true,
      onlyInViewport: false,
   },
   pagination: {
      el: '.slider-hero__pagination',
      clickable: true,
   },
});

/* ============================ sliders.js END ================================================================= */
