$(document).ready(function () {
   console.log('ready');
   searchElDropdown();
   handleBurgerMenu();
   redirectFromProductsPage();
   headerScrollHandle();
   copyContactsInfo();
   closeMobileMenuScrollToAnchor();
   scrollUp();
   sliderToggle();
});

$(window).on('load', function () {
   console.log('load');
});

function sliderToggle () {
   $(window).on('resize', function () {

      if ($(window).width() <= 666) {
         refs.sliderProducts.removeClass('swiper');
         refs.sliderProductsWrapper.removeClass('swiper-wrapper');
         refs.sliderProductsSlide.removeClass('swiper-slide');
      }
      if ($(window).width() >= 667) {
         sliderProducts = new Swiper('.slider-products', {
            slidesPerView: 2.1,
            watchOverflow: true,
            spaceBetween: 8,
            speed: 300,
            navigation: {
               nextEl: '.slider-products__button-next',
               prevEl: '.slider-products__button-prev',
            },
            pagination: {
               el: '.slider-products__pagination',
               clickable: true,
            },
            breakpoints: {
               667: {
                  slidesPerView: 4.25,
                  spaceBetween: 20,
               },
            },
            keyboard: {
               enabled: true,
               onlyInViewport: false,
            },
         });

         refs.sliderProducts.addClass('swiper');
         refs.sliderProductsWrapper.addClass('swiper-wrapper');
         refs.sliderProductsSlide.addClass('swiper-slide');
      }
   });
}

const handleBurgerMenu = () => {
   $(document).on('click', '.hamburger', function () {
      const ths = $(this);
      ths.toggleClass('open');
      $('.header__logo').toggleClass('hidden');
      $('.menu').toggleClass('menu-open');
      $('body').toggleClass('no-scroll');
   });
};

function headerScrollHandle () {
   $(window).scroll(function () {

      let height = $(window).scrollTop();

      if (height === 0) refs.header.addClass('no-scroll');
      if (height > 0) refs.header.removeClass('no-scroll');

      if (height > 900) refs.arrowUp.addClass('show');
      if (height < 800) refs.arrowUp.removeClass('show');
   });
}

function redirectFromProductsPage () {
   let height = $(window).scrollTop();
   if (height === 0) refs.header.addClass('no-scroll');
   if (height > 0) refs.header.removeClass('no-scroll');
}

function copyContactsInfo () {
   $(document).on('click', '.contacts__icon_email', function () {

      const value = refs.contactsEmail.text();
      navigator.clipboard.writeText(value);
   });

   $(document).on('click', '.contacts__icon_phone', function () {

      const value = refs.contactsPhone.text();
      navigator.clipboard.writeText(value);
   });
}

function closeMobileMenuScrollToAnchor () {
   $(document).on('click', '.menu__item', function () {
      if ($(window).width() <= 666) $('.hamburger').trigger("click");
   });
}

function scrollUp () {
   $(document).on('click', '.arrow-up', function () {
      $('body, html').animate({
         scrollTop: 0,
      }, 800);
   });
}

function gradientHeight () {
   const heightSliderProduct = refs.sliderProducts.height();
   const gradientHeight = refs.gradient.height();
   refs.gradient.height(gradientHeight + heightSliderProduct);
}

if ($(window).width() <= 666) gradientHeight();


