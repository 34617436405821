"use strict";
/* ============================ refs START ================================================================= */
const refs = Object.freeze({
   dropDownList: $('.dropdown__list'),
   dropDownBtn: $('.dropdown__button'),
   dropDownItems: $('.dropdown__item'),
   dropDownInput: $('.dropdown__input-hidden'),
   footer: $('footer'),
   header: $('.header'),
   contactsEmail: $('.contacts__email'),
   contactsPhone: $('.contacts__phone'),
   arrowUp: $('.arrow-up'),
   sliderProducts: $('.slider-products'),
   sliderProductsWrapper: $('.slider-products__wrapper'),
   sliderProductsSlide: $('.slider-products__slide'),
   gradient: $('.gradient'),
});

/* ============================ refs END ================================================================= */
